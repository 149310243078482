<template>
  <b-container id="schedule-resume-filters">
    <b-row align-v="center">
      <b-col cols="3" class="mt-4">
        <b-form-group class="patient-search">
          <Search class="icon ml-2" />
          <b-form-input
            v-model="filters.patient"
            placeholder="Buscar paciente"
            debounce="500"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="mt-4">
        <b-form-group>
          <MultiSelectPriority
            v-model="filters.priorityLevel"
            placeholder="Prioridade"
            label="Prioridade"
          />
        </b-form-group>
      </b-col>
      <b-col class="mt-4">
        <CheckBoxSelect
          label="Selecionar situação"
          placeholder="Selecionar situação"
          :value="filters.situation"
          :options="situations"
          trackBy="value"
          optionLabel="label"
          @onChange="setSituationFilter"
        />
      </b-col>
      <b-col class="mt-4">
        <DatabaseCheckBoxSelect
          type="procedures"
          placeholder="Procedimento"
          label="Procedimento"
          @onChange="updateProceduresFilter"
        />
      </b-col>
      <b-col class="mt-4">
        <DatabaseCheckBoxSelect
          type="healthPlans"
          placeholder="Convênio"
          label="Convênio"
          :multiple="false"
          @onChange="updateHealthPlansFilter"
        />
      </b-col>
      <b-col cols="1" class="text-align-center mt-13">
        <b-button
          id="date-popover"
          v-show="
            !moment(filters.date.getDate()).isSame(moment().toDate().getDate())
          "
          @click="resetDateFilter"
          variant="outline-primary"
        >
          <Calendar class="calendar-icon" />
        </b-button>
        <b-popover target="date-popover" triggers="hover" placement="bottom">
          Clique aqui para voltar para a data atual da agenda
        </b-popover>
      </b-col>
      <b-col cols="2">
        <div class="day-filter mt-13">
          <ChevronLeft class="chevron" @click="decrementDay" />
          <date-picker
            type="day"
            v-model="filters.date"
            format="DD [de] MMMM"
            :clearable="false"
            :lang="langDatePicker"
          ></date-picker>
          <ChevronRight class="chevron" @click="incrementDay" />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getFilterSituation } from '@/utils/filtersLocalStorageManager'

export default {
  name: 'ScheduleFilters',

  components: {
    Search: () => import('@/assets/icons/search.svg'),
    Calendar: () => import('@/assets/icons/calendar.svg'),
    CheckBoxSelect: () => import('@/components/CheckboxSelect'),
    DatabaseCheckBoxSelect: () => import('@/components/DatabaseCheckboxSelect'),
    ChevronLeft: () => import('@/assets/icons/chevron-left.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    MultiSelectPriority: () =>
      import('@/modules/schedule/components/MultiSelectPriority')
  },

  data() {
    return {
      filters: {
        patient: null,
        situation: getFilterSituation() ?? this.getSituationOptions(),
        date: this.moment().toDate()
      },
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      situations: this.getSituationOptions()
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('update', this.filters)
      },
      deep: true
    }
  },
  methods: {
    getSituationOptions() {
      return [
        { label: 'Todos', value: null },
        { label: 'Sem situação', value: 'SCHEDULED' },
        { label: 'Compareceu', value: 'ATTEND' },
        { label: 'Atendimeno finalizado', value: 'FINISHED' },
        { label: 'Confirmado', value: 'CONFIRMED' },
        { label: 'Confirmado pelo paciente', value: 'CONFIRMED_BY_PATIENT' },
        { label: 'Faltou', value: 'MISSED' },
        { label: 'Cancelado', value: 'CANCELLED' },
        { label: 'Cancelado pelo paciente', value: 'CANCELLED_BY_PATIENT' },
        { label: 'Aguardando atendimento', value: 'WAITING' },
        { label: 'Em atendimento médico', value: 'HAPPENING' },
        { label: 'Em exames', value: 'EXAMINATING' },
        { label: 'Dilatando', value: 'DILATING' },
        { label: 'Pré-consulta finalizada', value: 'PRE_FINISHED' },
        { label: 'Em pré-consulta', value: 'IN_PRE_CONSULTATION' },
        { label: 'Finalizado', value: 'FINISHED' }
      ]
    },
    setSituationFilter(value) {
      this.filters.situation = value
      localStorage.setItem('filterSituation', JSON.stringify(value))
    },
    resetDateFilter() {
      this.filters.date = this.moment().toDate()
    },
    incrementDay() {
      this.filters.date = this.moment(this.filters.date).add('1', 'd').toDate()
    },
    decrementDay() {
      this.filters.date = this.moment(this.filters.date)
        .subtract('1', 'd')
        .toDate()
    },
    updateHealthPlansFilter(value) {
      this.$set(this.filters, 'healthPlans', value)
    },
    updateProceduresFilter(value) {
      this.$set(this.filters, 'procedures', value)
    }
  }
}
</script>

<style lang="scss">
#schedule-resume-filters {
  .patient-search {
    .icon {
      stroke: var(--neutral-500);
      height: 20px;
      width: 20px;
      margin-top: 8px;
      position: absolute;
    }

    input {
      padding-left: 35px;
    }
  }

  .calendar-icon {
    width: 18px;
    height: 18px;
    fill: var(--blue-500);
  }
  .mt-13 {
    margin-top: 13px;
  }
  .day-filter {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;

    svg {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

    .mx-input {
      padding: 0 !important;
      text-align: center;
    }

    * {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }
  }
}
</style>
